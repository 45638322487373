(function () {
    'use strict';

    Controllers.TemplatesPageController = class TemplatesPageController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $state,
            $translate,
            $timeout,
            $window,
            _,
            AppStates,
            moment,
            AbTestService,
            AnalyticsService,
            DeviceService,
            Enums,
            FeaturesService,
            ModalService,
            NavigationService,
            OnboardingService,
            TemplatesManager,
            TemplatesViewService,
            UiPersistenceService,
            UIUtils,
            UsersManager,
            TemplatesRepositoryManager,
            PopupMessageService,
            CompaniesManager,
            ToastService,
            OtamStatusService,
            FlowsBetaUserTypeService
        ) {
            super($scope, $injector);
            this.__objectType = 'TemplatesWrapperController';
            AnalyticsService.reportEvent('ViewTemplates');
            this.NavigationService = NavigationService;
            this.TemplatesViewService = TemplatesViewService;
            this.AbTestService = AbTestService;
            this.AnalyticsService = AnalyticsService;
            this.OnboardingService = OnboardingService;
            this.TemplatesManager = TemplatesManager;
            this.Enums = Enums;
            this.FeaturesService = FeaturesService;
            this.DeviceService = DeviceService;
            this.ModalService = ModalService;
            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.$translate = $translate;
            this.$timeout = $timeout;
            this.$window = $window;
            this.moment = moment;
            this.UiPersistenceService = UiPersistenceService;
            this.UIUtils = UIUtils;
            this._ = _;
            this.TemplatesRepositoryManager = TemplatesRepositoryManager;
            this.PopupMessageService = PopupMessageService;
            this.UsersManager = UsersManager;
            this.ToastService = ToastService;
            this.OtamStatusService = OtamStatusService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;

            this.UsersManager.forceFetchCurrUser();
            this.user = UsersManager.getCurrUser();
            this.OtamStatusService.initializeAfterLogin();
            
            this.onCloseEmailSignatureModal = () => {
                this.shouldShowEmailSignature = false;
            }

            if (!this.FlowsBetaUserTypeService.hasFiles) {
                this.goToState(this.AppStates.root_core_navigation_my_templates);
            }


            CompaniesManager.on(this.Enums.PubSubTriggers.companyTemplatesUpdated, () => {
                this.company = CompaniesManager.getCurrCompany(true);
                this.registerOnce(this.company, 'success', () => {
                    this._populateTemplates();
                });
            });

            this.templateTypes = [];

            if (!this.FlowsBetaUserTypeService.hasNewFlowExp) {
                this.templateTypes.push({
                    key: "package",
                    uiKey: "package",
                    icon: "icon-hb-nx-cart-42",
                    order: 9,
                    title: $translate.instant("TEMPLATES_PAGE.PACKAGE._TITLE_PLURAL_"),
                    titleSingular: $translate.instant("TEMPLATES_PAGE.PACKAGE._TITLE_SINGULAR_"),
                    description: $translate.instant("TEMPLATES_PAGE.PACKAGE._DESCRIPTION_"),
                    headline: $translate.instant("TEMPLATES_PAGE.PACKAGE._HEADLINE_"),
                    subheadline: $translate.instant("TEMPLATES_PAGE.INVOICE._SUBHEADLINE_"),
                    templateModel: this.TemplatesViewService.package,
                    bannerSubtitle: $translate.instant('TEMPLATES_PAGE.PACKAGE.BANNER._SUBTITLE_'),
                    bannerDescription: $translate.instant('TEMPLATES_PAGE.PACKAGE.BANNER._DESCRIPTION_'),
                    bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289493/system_web/templates/banner-illustration-package.svg',
                    graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553592667/system_web/templates/Template_preview_Package.png",
                    iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/package-icon.svg",
                    iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-package.svg",
                    dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1587512905/honeybook_cdn/file-types/package.svg',
                    features: [
                        $translate.instant("TEMPLATES_PAGE.PACKAGE.FEATURES._CUSTOMIZE_"),
                        $translate.instant("TEMPLATES_PAGE.PACKAGE.FEATURES._PRESENT_"),
                        $translate.instant("TEMPLATES_PAGE.PACKAGE.FEATURES._ADD_"),
                    ],
                });

                this.templateTypes.push({
                    key: "email",
                    uiKey: "email",
                    icon: "icon-hb-nx-email",
                    order: 11,
                    title: $translate.instant("TEMPLATES_PAGE.EMAIL._TITLE_PLURAL_"),
                    titleSingular: $translate.instant("TEMPLATES_PAGE.EMAIL._TITLE_SINGULAR_"),
                    description: $translate.instant("TEMPLATES_PAGE.EMAIL._DESCRIPTION_"),
                    headline: $translate.instant("TEMPLATES_PAGE.EMAIL._HEADLINE_"),
                    subheadline: $translate.instant("TEMPLATES_PAGE.EMAIL._SUBHEADLINE_"),
                    templateModel: this.TemplatesViewService.email,
                    bannerSubtitle: $translate.instant('TEMPLATES_PAGE.EMAIL.BANNER._SUBTITLE_'),
                    bannerDescription: $translate.instant('TEMPLATES_PAGE.EMAIL.BANNER._DESCRIPTION_'),
                    bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289492/system_web/templates/banner-illustration-email.svg',
                    graphicUrl: "//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1553594503/system_web/templates/Template_preview_email.png",
                    iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/email-icon.svg",
                    iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-email.svg",
                    dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1587512905/honeybook_cdn/file-types/emails-cannon.svg',
                    features: [
                        $translate.instant("TEMPLATES_PAGE.EMAIL.FEATURES._SAVE_"),
                        $translate.instant("TEMPLATES_PAGE.EMAIL.FEATURES._EASY_"),
                        $translate.instant("TEMPLATES_PAGE.EMAIL.FEATURES._INCLUDE_"),
                    ],
                });

                this.templateTypes.push({
                    key: "emailSignature",
                    uiKey: "emailSignature",
                    icon: "icon-hb-nx-signature",
                    order: 12,
                    title: $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE._TITLE_PLURAL_"),
                    titleSingular: $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE._TITLE_SINGULAR_"),
                    description: $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE._DESCRIPTION_"),
                    headline: $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE._HEADLINE_"),
                    subheadline: $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE._SUBHEADLINE_"),
                    templateModel: this.TemplatesViewService.emailSignature,
                    bannerSubtitle: $translate.instant('TEMPLATES_PAGE.EMAIL_SIGNATURE.BANNER._SUBTITLE_'),
                    bannerDescription: $translate.instant('TEMPLATES_PAGE.EMAIL_SIGNATURE.BANNER._DESCRIPTION_'),
                    bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289492/system_web/templates/banner-illustration-email.svg',
                    graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553594504/system_web/templates/Template_preview_email_signature.png",
                    iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/signature-icon.svg",
                    iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-email.svg",
                    dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/contract.svg',
                    features: [
                        $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE.FEATURES._CUSTOMIZE_"),
                        $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE.FEATURES._LOOK_"),
                        $translate.instant("TEMPLATES_PAGE.EMAIL_SIGNATURE.FEATURES._ADD_"),
                    ],
                });
            }

            if (this.FlowsBetaUserTypeService.hasFiles) {
                this.templateTypes.push( {
                        key: "invoice",
                        uiKey: "invoice",
                        order: 1,
                        icon: "icon-hb-nx-invoice",
                        title: $translate.instant("TEMPLATES_PAGE.INVOICE._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.INVOICE._TITLE_SINGULAR_"),
                        description:$translate.instant( "TEMPLATES_PAGE.INVOICE._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.INVOICE._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.INVOICE._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.invoice,
                        bannerSubtitle: $translate.instant('TEMPLATES_PAGE.INVOICE.BANNER._SUBTITLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.INVOICE.BANNER._DESCRIPTION_'),
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289493/system_web/templates/banner-illustration-invoice.svg',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553592667/system_web/templates/Template_preview_invoice.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/invoice-icon.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-invoice.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/invoice.svg',
                        features: [
                            $translate.instant( "TEMPLATES_PAGE.INVOICE.FEATURES._CLIENTS_"),
                            $translate.instant("TEMPLATES_PAGE.INVOICE.FEATURES._SET_"),
                            $translate.instant("TEMPLATES_PAGE.INVOICE.FEATURES._MAKE_"),
                        ],
                    },
                    {
                        key: "agreement",
                        uiKey: "agreement",
                        order: 2,
                        icon: "icon-hb-nx-contract",
                        title: $translate.instant("TEMPLATES_PAGE.AGREEMENT._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.AGREEMENT._TITLE_SINGULAR_"),
                        description: $translate.instant("TEMPLATES_PAGE.AGREEMENT._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.AGREEMENT._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.AGREEMENT._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.agreement,
                        bannerSubtitle: $translate.instant('TEMPLATES_PAGE.AGREEMENT.BANNER._SUBTITLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.AGREEMENT.BANNER._DESCRIPTION_'),
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588200397/system_web/templates/banner-illustration-contact-form.svg',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553592667/system_web/templates/Template_preview_Contract.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/contract-icon.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-contract.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/contract.svg',
                        features: [
                            $translate.instant("TEMPLATES_PAGE.AGREEMENT.FEATURES._SMART_"),
                            $translate.instant("TEMPLATES_PAGE.AGREEMENT.FEATURES._CLIENT_"),
                            $translate.instant("TEMPLATES_PAGE.AGREEMENT.FEATURES._SMART_BAR_"),
                        ],
                    },
                    {
                        key: "proposal",
                        uiKey: "proposal",
                        order: 3,
                        icon: "icon-hb-nx-proposal-32",
                        title: $translate.instant("TEMPLATES_PAGE.PROPOSAL._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.PROPOSAL._TITLE_SINGULAR_"),
                        description: $translate.instant("TEMPLATES_PAGE.PROPOSAL._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.PROPOSAL._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.PROPOSAL._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.proposal,
                        bannerSubtitle: $translate.instant('TEMPLATES_PAGE.PROPOSAL.BANNER._SUBTITLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.PROPOSAL.BANNER._DESCRIPTION_'),
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289494/system_web/templates/banner-illustration-proposal.svg',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553592667/system_web/templates/Template_preview_Proposal.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/proposal-icon.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-proposal.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/proposal.svg',
                        features: [
                            $translate.instant("TEMPLATES_PAGE.PROPOSAL.FEATURES._INVOICE_"),
                            $translate.instant("TEMPLATES_PAGE.PROPOSAL.FEATURES._CLIENTS_"),
                            $translate.instant("TEMPLATES_PAGE.PROPOSAL.FEATURES._TOTALLY_"),
                        ],
                    });



                this.templateTypes.push({
                    key: "brochure",
                    uiKey: "brochure",
                    order: 5,
                    icon: "icon-hb-nx-brochure-32",
                    title: $translate.instant("TEMPLATES_PAGE.BROCHURE._TITLE_PLURAL_"),
                    titleSingular: $translate.instant("TEMPLATES_PAGE.BROCHURE._TITLE_SINGULAR_"),
                    description: $translate.instant("TEMPLATES_PAGE.BROCHURE._DESCRIPTION_"),
                    headline: $translate.instant("TEMPLATES_PAGE.BROCHURE._HEADLINE_"),
                    subheadline: $translate.instant("TEMPLATES_PAGE.BROCHURE._SUBHEADLINE_"),
                    templateModel: this.TemplatesViewService.brochure,
                    bannerSubtitle: $translate.instant('TEMPLATES_PAGE.BROCHURE.BANNER._SUBTITLE_'),
                    bannerDescription: $translate.instant('TEMPLATES_PAGE.BROCHURE.BANNER._DESCRIPTION_'),
                    bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289492/system_web/templates/banner-illustration-brochure.svg',
                    graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553592667/system_web/templates/Template_preview_brochure.png",
                    iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/brochure-icon.svg",
                    iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-brochure.svg",
                    dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/brochure.svg',
                    features: [
                        $translate.instant("TEMPLATES_PAGE.BROCHURE.FEATURES._CUSTOMIZABLE_"),
                        $translate.instant("TEMPLATES_PAGE.BROCHURE.FEATURES._LAYOUTS_"),
                        $translate.instant("TEMPLATES_PAGE.BROCHURE.FEATURES._AUTOMATION_"),
                    ],
                });

                if (UsersManager.getCurrUser().isCompanyOwner() && this.FlowsBetaUserTypeService.hasFlows) {
                    this.templateTypes.push({
                        key: "questionnaire",
                        uiKey: "questionnaire_promote_flow",
                        order: 4,
                        icon: "icon-hb-nx-questionnaire-42",
                        title: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._TITLE_SINGULAR_"),
                        description: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.questionnaire,
                        bannerTitle: $translate.instant('TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._TITLE_'),
                        bannerTitleStyle: $translate.instant('TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._TITLE_STYLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._PROMOTE_DESCRIPTION_'),
                        bannerDescriptionStyle: $translate.instant('TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._DESCRIPTION_STYLE_'),
                        bannerLinkContent: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._LINK_"),
                        bannerLinkEvent: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._LINK_EVENT_"),
                        bannerLinkUrl: AppStates.root_core_navigation_flows_template_gallery,
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1612088100/system_web/templates/banner_questionnaire_new.png',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553594505/system_web/templates/Template_preview_questionnaire.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/questionnaire-icon.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-questionnaire.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/questionnaire.svg',
                        features: [
                            $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.FEATURES._CUSTOMIZE_"),
                            $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.FEATURES._PREMADE_"),
                            $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.FEATURES._USE_"),
                        ],
                    });
                } else {
                    this.templateTypes.push({
                        key: "questionnaire",
                        uiKey: "questionnaire",
                        order: 4,
                        icon: "icon-hb-nx-questionnaire-42",
                        title: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._TITLE_SINGULAR_"),
                        description: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.questionnaire,
                        bannerSubtitle: $translate.instant('TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._SUBTITLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.QUESTIONNAIRE.BANNER._DESCRIPTION_'),
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588289495/system_web/templates/banner-illustration-questionnaire.svg',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1553594505/system_web/templates/Template_preview_questionnaire.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/questionnaire-icon.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-questionnaire.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535011004/honeybook_cdn/file-types/questionnaire.svg',
                        features: [
                            $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.FEATURES._CUSTOMIZE_"),
                            $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.FEATURES._PREMADE_"),
                            $translate.instant("TEMPLATES_PAGE.QUESTIONNAIRE.FEATURES._USE_"),
                        ],
                    });
                }
            }

            if (this.FlowsBetaUserTypeService.hasFlows && !this.isMobile && this.FlowsBetaUserTypeService.hasFiles && !this.FlowsBetaUserTypeService.hasNewFlowExp) {
                this.templateTypes.push({
                    key: "flow",
                    uiKey: "flow_promote_flow",
                    icon: "icon-hb-file-sparks-24",
                    order: 0,
                    showNewLabel: true,
                    title: $translate.instant("TEMPLATES_PAGE.FLOW._TITLE_PLURAL_"),
                    description: $translate.instant("TEMPLATES_PAGE.FLOW._DESCRIPTION_"),
                    titleSingular: $translate.instant("TEMPLATES_PAGE.FLOW._TITLE_SINGULAR_"),
                    headline: $translate.instant("TEMPLATES_PAGE.FLOW._HEADLINE_"),
                    subheadline: $translate.instant("TEMPLATES_PAGE.FLOW._SUBHEADLINE_"),
                    templateModel: this.TemplatesViewService.flow,
                    bannerTitle: $translate.instant('TEMPLATES_PAGE.FLOW.BANNER._TITLE_'),
                    bannerTitleStyle: $translate.instant('TEMPLATES_PAGE.FLOW.BANNER._TITLE_STYLE_'),
                    bannerDescription: $translate.instant("TEMPLATES_PAGE.FLOW._DESCRIPTION_"),
                    bannerDescriptionStyle: $translate.instant('TEMPLATES_PAGE.FLOW.BANNER._DESCRIPTION_STYLE_'),
                    bannerLinkContent: $translate.instant("TEMPLATES_PAGE.FLOW.BANNER._LINK_"),
                    bannerLinkEvent: $translate.instant("TEMPLATES_PAGE.FLOW.BANNER._LINK_EVENT_"),
                    bannerLinkUrl: AppStates.root_core_navigation_flows_template_gallery,
                    bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1612087871/system_web/templates/banner_flows_new.png',
                    graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1586081648/system_web/templates/template-preview-promo.png",
                    iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1604415362/system_web/templates/flow-mweb.svg",
                    iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1646671825/system_web/templates/file-sparks-24.svg",
                    dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1604248326/honeybook_cdn/file-types/illustrations-empty-state-planning.svg',
                    features: [],
                });
            }


            if (UsersManager.getCurrUser().isCompanyOwner() || UsersManager.getCurrUser().hasCompanyAdminPermissions()) {
                this.templateTypes.push({
                    key: "contactForm",
                    uiKey: "contactForm",
                    icon: "icon-hb-nx-tool-contact-form-42",
                    order: 10,
                    title: $translate.instant("TEMPLATES_PAGE.CONTACT_FORM._TITLE_PLURAL_"),
                    titleSingular: $translate.instant("TEMPLATES_PAGE.CONTACT_FORM._TITLE_SINGULAR_"),
                    description: $translate.instant("TEMPLATES_PAGE.CONTACT_FORM._DESCRIPTION_"),
                    headline: $translate.instant("TEMPLATES_PAGE.CONTACT_FORM._HEADLINE_"),
                    subheadline: $translate.instant("TEMPLATES_PAGE.CONTACT_FORM._SUBHEADLINE_"),
                    templateModel: this.TemplatesViewService.contactForm,
                    bannerSubtitle: $translate.instant('TEMPLATES_PAGE.CONTACT_FORM.BANNER._SUBTITLE_'),
                    bannerDescription: $translate.instant('TEMPLATES_PAGE.CONTACT_FORM.BANNER._DESCRIPTION_'),
                    bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588200397/system_web/templates/banner-illustration-contact-form.svg',
                    graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1568022273/system_web/templates/Template_preview_contact_form.png",
                    iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1533683868/system_web/templates/contact-form-icon.svg",
                    iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-contact-form.svg",
                    dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1587512905/honeybook_cdn/file-types/telescope-contact-form.svg',
                    features: [
                        $translate.instant("TEMPLATES_PAGE.CONTACT_FORM.FEATURES._CUSTOMIZABLE_"),
                        $translate.instant("TEMPLATES_PAGE.CONTACT_FORM.FEATURES._GENERATED_"),
                        $translate.instant("TEMPLATES_PAGE.CONTACT_FORM.FEATURES._AUTO_RESPONSE_"),
                    ],
                });

                if (this.user.shouldShowGiftCardFeature() && !this.FlowsBetaUserTypeService.hasNewFlowExp) {
                    this.templateTypes.push({
                        key: "giftCard",
                        uiKey: "giftCard",
                        icon: "icon-hb-nx-gift",
                        order: 8,
                        showNewLabel: true,
                        title: $translate.instant("TEMPLATES_PAGE.GIFT_CARD._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.GIFT_CARD._TITLE_SINGULAR_"),
                        description: $translate.instant("TEMPLATES_PAGE.GIFT_CARD._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.GIFT_CARD._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.GIFT_CARD._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.giftCard,
                        bannerSubtitle: $translate.instant('TEMPLATES_PAGE.GIFT_CARD.BANNER._SUBTITLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.GIFT_CARD.BANNER._DESCRIPTION_'),
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1588200397/system_web/templates/banner-illustration-gift-card.svg',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1586081648/system_web/templates/template-preview-promo.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1585765327/system_web/templates/gift-card.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1587502416/system_web/templates/nb-gift-card.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1535461215/icons/gift_svg.svg',
                        features: [
                            $translate.instant("TEMPLATES_PAGE.GIFT_CARD.FEATURES._OFFER_"),
                            $translate.instant("TEMPLATES_PAGE.GIFT_CARD.FEATURES._DISCOUNT_"),
                            $translate.instant("TEMPLATES_PAGE.GIFT_CARD.FEATURES._REDEEM_"),
                        ],
                    });
                }

                if (this.user.isFeatureActive(this.FeaturesService.rolloutFeatures.blockTemplates) && !this.isMobile) {
                    this.templateTypes.push({
                        key: "blockTemplate",
                        icon: "icon-hb-file-sparks-24",
                        order: 7,
                        showNewLabel: true,
                        title: $translate.instant("TEMPLATES_PAGE.BLOCK._TITLE_PLURAL_"),
                        titleSingular: $translate.instant("TEMPLATES_PAGE.BLOCK._TITLE_SINGULAR_"),
                        description: $translate.instant("TEMPLATES_PAGE.BLOCK._DESCRIPTION_"),
                        headline: $translate.instant("TEMPLATES_PAGE.BLOCK._HEADLINE_"),
                        subheadline: $translate.instant("TEMPLATES_PAGE.BLOCK._SUBHEADLINE_"),
                        templateModel: this.TemplatesViewService.flow,
                        bannerSubtitle: $translate.instant('TEMPLATES_PAGE.BLOCK.BANNER._SUBTITLE_'),
                        bannerDescription: $translate.instant('TEMPLATES_PAGE.BLOCK.BANNER._DESCRIPTION_'),
                        bannerGraphicUrl: '//res.cloudinary.com/honeybook/image/upload/v1604491760/system_web/templates/banner-flows.svg',
                        graphicUrl: "//res.cloudinary.com/honeybook/image/upload/dpr_auto,f_auto,fl_lossy,q_auto/v1586081648/system_web/templates/template-preview-promo.png",
                        iconUrl: "//res.cloudinary.com/honeybook/image/upload/v1604415362/system_web/templates/flow-mweb.svg",
                        iconSimpleUrl: "//res.cloudinary.com/honeybook/image/upload/v1646671825/system_web/templates/file-sparks-24.svg",
                        dropdownIllustrationUrl: '//res.cloudinary.com/honeybook/image/upload/v1604248326/honeybook_cdn/file-types/illustrations-empty-state-planning.svg',
                        features: [],
                    });
                }
            }

            // Defaults
            // ---

            // Sort by 'newest'
            const defaultSortKey = '-created_at';
            const defaultViewKey = 'card';
            let defaultFilterKey = 'invoice';

            // If user started onboarding from one of the contracts lps, let the agreements be active.
            if (this.OnboardingService.isContractsEntryPoint()) {
                defaultFilterKey = 'agreement';
                this.OnboardingService.skipPreAppOnboarding(); // Move away from the intro panels stage.
            }

            this.sortOrders = [
                {
                    display: $translate.instant('TEMPLATES_PAGE.SORT._DATE_CREATED_ASC_'),
                    key: '-created_at',
                },
                {
                    display: $translate.instant('TEMPLATES_PAGE.SORT._DATE_CREATED_DES_'),
                    key: 'created_at',
                },
                {
                    display: $translate.instant('TEMPLATES_PAGE.SORT._ALPHABETICAL_'),
                    key: 'title',
                }
            ];

            const defaultBannerStates = {
                invoice: false,
                agreement: false,
                proposal: false,
                questionnaire: false,
                brochure: false,
                giftCard: false,
                package: false,
                email: false,
                emailSignature: false,
                contactForm: false,
                all: false
            };

            const viewOptionsPersistence = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.templatesLibraryViewOptions,
                {
                    filterKey: defaultFilterKey,
                    sortKey: defaultSortKey,
                    viewKey: defaultViewKey,
                    dismissedBannerKeys: defaultBannerStates
                }
            );

            // Set the default view options
            this.activeSort = this.sortOrders.find( sort => sort.key === viewOptionsPersistence.sortKey) || {};
            this.activeFilterType = this.templateTypes.find(type => type.key === viewOptionsPersistence.filterKey) || {};
            this.templateFileListView = (viewOptionsPersistence.viewKey === 'list');

            // Filtering can also be set from state params
            const typeFromParams = $state.params.templateTypeKey;
            const promoteTypeFromParams = $state.params.promoteTemplateTypeKey;
            if (typeFromParams === 'all') {
                this.clearFilters();
            } else {
                let templateType =
                    this.FlowsBetaUserTypeService.hasFlows && promoteTypeFromParams && this.hasNotDismissedBanner(promoteTypeFromParams) ?
                        this.templateTypes.find(type => type.uiKey === promoteTypeFromParams) :
                        this.templateTypes.find(type => type.key === typeFromParams);
                if (templateType) {
                    this.activeFilterType = templateType;
                }
            }

            this.dismissedBannerPersistences = viewOptionsPersistence.dismissedBannerKeys || defaultBannerStates;

            // The first few types by the order property are
            // the 'file' types, the later ones are 'other' types
            const orderOfLastFileType = 6;
            this.fileTemplateTypes = this.templateTypes.filter(t => t.order <= orderOfLastFileType);
            this.otherTemplateTypes = this.templateTypes.filter(t => orderOfLastFileType < t.order);

            // Adds up all the templates in each of the types
            this.totalTemplatesCount = 0;
            for (let type of this.templateTypes) {
                let typeCount = type.templateModel.templatesList() ? type.templateModel.templatesList().length : 0;
                this.totalTemplatesCount += typeCount;
            }

            this.company = this.user.company;
            if (!this.company.wasFullyFetched()) {
                this.registerOnce(this.company, 'success', function gotCompany() {
                    this._populateTemplates();
                }.bind(this));
            } else {
                this._populateTemplates();
            }

            this.hasNotSeenExplanationModal = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.templatesExplainModal,
                { hasNotSeen: true }
            )['hasNotSeen'];

            this.isShowDepricationFileTemplatesBanner = this.shouldShowDepricationBanner();

            if (!this.isMobile) {
                const queryParams = document.location.href.split('?')[1];
                if((queryParams && (queryParams.includes('pre_otam') || queryParams.includes('show_otam')) || $state.params.pre_otam === 'inProduct') && this.OtamStatusService.shouldSeePreOtamModal()){
                    const source = $state.params.pre_otam === 'inProduct' ? 'try it banner' : 'marketingEmail';
                    this.ModalService.openPreMigrateUserModal(source);
                }
            }

            TemplatesViewService.onExitEditor();
        }

        _populateTemplates() {
            this.templates = [];
            this.templateTypes.forEach(templateType => {
                const templatesList = templateType.templateModel.templatesList() || [];
                const enrichedTemplates = templatesList.map(template => {
                    // Many methods require template AND that template's view model
                    // Here each template is getting its VM appended as a property
                    // as well as some other useful data for the view
                    template.viewModel = templateType.templateModel;
                    template.iconSimpleUrl = templateType.iconSimpleUrl;
                    template.key = this.UIUtils.convertToCamelCase(templateType.templateModel.name);
                    // How do templates not have a uniform type???
                    // This is a hack for now until we find a better way to guarantee templates_manager methods work
                    template.type = template.type || this.UIUtils.convertToCamelCase(templateType.templateModel.name);
                    return template;
                })
                this.templates.push(enrichedTemplates);
            })
            // Flatten for repeating, filtering, and searching
            this.templates = this._.flatten(this.templates);
            // Remove templates that don't have _id
            this.templates = this.templates.filter(t => t._id);

            this.updateFilters(this.activeFilterType);
        }

        shouldShowDepricationBanner() {
            return this.FlowsBetaUserTypeService.hasNewFlowExp && !this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.dontShowDepricationFileTemplatesBanner, {}).hide;
        }

        shouldSeeIntrocutionBanner() {
            return !this.FlowsBetaUserTypeService.hasNewFlowExp && this.hasNotDismissedBanner(this.activeFilterType.uiKey);
        }

        formatTitle(templateTitle) {
            if (!templateTitle) {
                return this.$translate.instant('TEMPLATES_PAGE._TEMPLATE_NO_TITLE_')
            }

            let formattedTemplateTitle = templateTitle;
            let stringToBeReplaced = `<span class="templates__template-list-title--hb-template">${this.$translate.instant('TEMPLATES_PAGE._HONEYBOOK_TEMPLATE_')}</span>`;

            if (templateTitle.endsWith(' | HB TEMPLATE') || templateTitle.endsWith('- HB TEMPLATE') || templateTitle.endsWith(' | HB TEMPLATES')) {
                formattedTemplateTitle = templateTitle.replace(' | HB TEMPLATE', stringToBeReplaced).replace('- HB TEMPLATE', stringToBeReplaced).replace(' | HB TEMPLATES', stringToBeReplaced);
            }

            return formattedTemplateTitle;
        }

        goToBannerLink(eventName, eventLink) {
            this.AnalyticsService.trackAction(this, eventName, {
                origin: this.activeFilterType.titleSingular + ' banner'
            });
            this.$state.go(eventLink);
        }

        goToTemplates(templateKey) {
            this.TemplatesViewService.goToTemplatesEditor(templateKey);
        }

        goToSpecificTemplate(e, templateKey, templateId) {
            e.preventDefault();
            this.TemplatesViewService.goToTemplatesEditor(templateKey, templateId);
        }

        getTemplateFromKey(key) {
            return this.templateTypes.find( template => template.key === key );
        }

        handleTemplateClick(e, templateKey) {
            e.preventDefault();
            const template = this.getTemplateFromKey(templateKey);

            if (this.isMobile) {

                // There's only one emailSignature template,
                // don't bother opening the accordion — just go there
                if (templateKey === 'emailSignature') {
                    this.goToTemplates(templateKey)
                }

                template.isOpen = !template.isOpen;

                // Ensures you see all the template options
                // when you open the bottom accordion menu item
                if (this.isLastTemplate(template)) {
                    this.$timeout( () =>
                        $('html, body').animate({ scrollTop: document.body.scrollHeight }, 500), 100);
                }

            } else {
                this.goToTemplates(templateKey);
            }
        }

        isLastTemplate(template) {
            var lastPosition = this.templateTypes.reduce((acc, item) => {
                return (item.order > acc ? item.order : acc)
            }, 0);

            return template.order === lastPosition;
        }

        filterByType(type) {
            if (type && type.key !== this.activeFilterType.key) {
                this.updateFilters(type);
                this.saveStateUiPersistence();
            }
        }

        openMicroSite() {
            this.$window.open("https://smartfiles.honeybook.com/",'_blank');
        }

        updateFilters(type) {
            // Need to check type.key because no active filter
            // is represented as {} which is truthy
            if (type && type.key && type.key !== 'all') {
                // Change the filter
                this.filteredTemplates =
                    this.templates.filter(template => type.key === template.key);
                // Persist this choice in the view
                this.activeFilterType = type;
            } else {
                this.clearFilters();
            }
        }

        clearFilters() {
            this.activeFilterType = {};
            this.filteredTemplates = this.templates;
        }

        onCreate(templateTypeKey) {
            // new 'giftCard' type is handled a little different
            if (templateTypeKey === 'giftCard') {
                this.$state.go(
                    this.AppStates.root_core_navigation_templatesEditor,
                    {
                        templateModelKey: 'giftCard',
                        shouldShowEmptyState: true
                    }
                );
            } else {
                const templateType = this.templateTypes.find(type => type.key === templateTypeKey)
                this.TemplatesViewService.addNewTemplate(templateType.templateModel);
            }
        }

        onDelete(template) {
            // For some reason, we can't capture the "click background" close
            // so we can't remove the loading effect. Potential UI bug.
            this.templateIdIsLoading = template._id;
            this.TemplatesViewService.deleteTemplate(template, template.viewModel, 'templates')
            .then(() => {
                this.templateIdIsLoading = null;
                this._populateTemplates();
            })
            .catch(() => {
                this.templateIdIsLoading = null;
            });
        }

        onClone(template) {
            this.TemplatesViewService.openSaveAsModal(template, template.viewModel)
            .then(() => {
                this._populateTemplates();
            });
        }

        onDefault(template) {
            this.templateIdIsLoading = template._id;
            this.TemplatesViewService.setAsDefault(template, template.viewModel)
            .then(() => {
                this.templateIdIsLoading = null;
                this._populateTemplates();
            });
        }

        onPublish(template) {
            this.TemplatesRepositoryManager.publishTemplate(template._id, 'file_gallery', template.title, '',template.type, [], '')
                .then(() => {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.success, "Successfully published template!");
                })
                .catch(resp => {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                });
        }

        onSelect(template) {
            if (template.type === 'emailSignature') {
                this.shouldShowEmailSignature = true;
                return;
            }
            this.templateIdIsLoading = template._id;
            this.TemplatesViewService.goToTemplatesEditor(template.key, template._id)
            this.templateIdIsLoading = null;
        }

        updateSort(sort) {
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.templates_sort,
                { type: sort.display }
            );
            this.activeSort = sort;
            this.saveStateUiPersistence();
        }

        getTitleAndCountFromType(templateType) {
            let title = ``;
            let count = ``;
            if (templateType) {
                title = templateType.title;
                const templatesListLength = templateType.templateModel.templatesList() ? templateType.templateModel.templatesList().length : 0;
                count = templatesListLength > 0 ? `(${templatesListLength})` : ``;
            } else {
                title = this.$translate.instant('TEMPLATES_PAGE._ALL_');
                count = this.totalTemplatesCount > 0 ? `(${this.totalTemplatesCount})` : ``;
            }
            return `${title} ${count}`;
        }

        shouldShowCreateNewCard() {
            return this.activeFilterType.key
                && this.activeFilterType.key !== 'emailSignature'
                && !this.templateFileListView;
        }

        setCardOrListView(type) {
            this.templateFileListView = (type === 'list');
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.templates_toggle_view,
                { source: this.templateFileListView ? 'list' : 'card' }
            );
            this.saveStateUiPersistence();
        }

        saveStateUiPersistence() {
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.templatesLibraryViewOptions,
                {
                    filterKey: this.activeFilterType.key || 'all',
                    sortKey: this.activeSort.key,
                    viewKey: this.templateFileListView ? 'list' : 'card',
                    dismissedBannerKeys: this.dismissedBannerPersistences
                }
            );
        }

        dismissBanner(activeFilterKey) {
            // TODO: Have a better way to handle 'all' type
            const key = activeFilterKey || 'all';
            this.dismissedBannerPersistences = this._.extend(this.dismissedBannerPersistences, {[key]: true})
            this.saveStateUiPersistence();
        }

        hasNotDismissedBanner(activeFilterKey) {
            // TODO: Have a better way to handle 'all' type
            const key = activeFilterKey || 'all';
            return !this.dismissedBannerPersistences[key];
        }

    };

}());
